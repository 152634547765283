@import url(https://use.typekit.net/suw4gro.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: 'Abingdon Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Abingdon Regular'),
    url("../../static/media/Abingdon Regular.cfe673b0.woff") format('woff');
}

@font-face {
  font-family: 'Abingdon Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Abingdon Italic'),
    url("../../static/media/Abingdon Italic.4b804d3b.woff") format('woff');
}

@font-face {
  font-family: 'Abingdon Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Abingdon Bold'), url("../../static/media/Abingdon Bold.3ea455a0.woff") format('woff');
}

@font-face {
  font-family: 'Abingdon Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Abingdon Bold Italic'),
    url("../../static/media/Abingdon Bold Italic.bd1db8de.woff") format('woff');
}

.MuiTypography-h1
/* .MuiTypography-root .MuiTypography-h1 */ {
  font-family: 'Abingdon Bold' !important;
  color: #7151a1;
}

/* .Abingdon-bold {
  font-family: 'Abingdon Bold' !important;
} */

.App {
  text-align: center;
  font-family: ff-tisa-web-pro, serif;
  height: 100vh;
}

.App-header {
  background-color: #87bc9e;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

table,
th,
td {
  border: solid 2px;
  text-align: center;
  border-collapse: collapse;
  padding: 8px;
  /* word-break: break-all; */
}

table {
  table-layout: fixed;
  width: 100%;
}

td {
  width: 20%;
  font-size: 0.75em;
}

p {
  color: #17405e;
  padding-bottom: 2.5%;
  font-weight: 500;
  font-size: 1.25vw;
  line-height: 140%;
}

li {
  padding-top: 8px;
}

h5 {
  color: #17405e;
  font-size: 1.25vw;
  margin: 4%;
  font-family: ff-tisa-web-pro, serif;
}

h3 {
  color: #17405e;
  font-size: 2vw;
  font-family: ff-tisa-web-pro, serif;
  font-weight: 700;
}

h4 {
  color: #17405e;
  font-size: 1.5vw;
  font-family: ff-tisa-web-pro, serif;
  font-weight: 400;
}

h2 {
  color: #17405e;
  font-size: 2.5vw;
  font-family: ff-tisa-web-pro, serif;
  font-weight: 100;
}

#content-div {
  padding-left: 10%;
  padding-right: 10%;
}

#content-container {
  padding-top: 5%;
}

#quote-image {
  width: 50vw;
}

#color-div {
  padding: 8px;
  padding-left: 32px;
  padding-right: 32px;
  border-radius: 8px;
  width: 90%;
  margin-top: 32px;
}

/* button {
  background: linear-gradient(90deg, #d1e6a7 0%, #87bc9e 100%);
  padding: 16px;
  margin: 40px;
  border-radius: 32px;
  color: #17405e;
  font-family: ff-tisa-web-pro, serif;
  font-weight: 400;
  border: none;
  font-size: 1.25em;
  text-decoration: none;
} */

button:hover {
  background: #d1e6a7;
}

#button-text {
  padding-left: 32px;
  padding-right: 32px;
  text-decoration: none;
  color: #17405e;
  font-family: ff-tisa-web-pro, serif;
  font-weight: 700;
}

#button-grid-left {
  text-align: left;
}

#button-grid-right {
  text-align: right;
}

#grid-button {
  width: 60%;
  margin: 16px;
}

#card-title {
  margin-left: 10%;
  margin-right: 10%;
}

#card-text {
  text-align: left;
  padding: 5%;
  font-weight: 540;
  font-family: ff-tisa-web-pro, serif;
  color: #464646;
  line-height: 140%;
}

#sidebar-div {
  width: 30%;
}

#cover-logo {
  width: 200px;
  padding: 50px;
  display: inline;
  padding-right: 0px;
}

#cover-logo-prim {
  padding: 16px;
  text-align: center;
}

#cover-text {
  color: #87bc9e;
  display: inline;
  font-family: ff-tisa-web-pro, serif;
  font-weight: 700;
  font-size: 3vw;
}

#cover-sub-text {
  color: #ffffff;
  display: inline;
  font-family: ff-tisa-web-pro, serif;
  font-weight: 600;
  font-size: 2vw;
}

#cover-sub-text a {
  color: #87bc9e;
}

#cover-text-container {
  margin-top: 64px;
  margin-bottom: 32px;
}

#header-image {
  margin: 0 auto;
  width: 35%;
  margin-bottom: 0;
}

#header-stripe {
  background-color: #17405e;
  height: 8px;
}

#header-title {
  background-color: #17405e;
  height: 100%;
  width: 60%;
  /* left top, right top, right bottom, right bottom */
  -webkit-clip-path: polygon(0 0, 95% 0, 100% 100%, 0 100%);
  clip-path: polygon(0 0, 95% 0, 100% 115%, 0 115%);
  text-align: left;
  padding-top: 1%;
  padding-left: 8%;
  margin-bottom: -1px;
}

#horizontal-card {
  -webkit-justify-content: center;
          justify-content: center;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: 100%;
}

#horizontal-item {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

#header-lower-stripe {
  background-color: #17405e;
  height: 22px;
}

#header-text {
  color: #87bc9e;
  display: inline;
  font-family: ff-tisa-web-pro, serif;
  font-weight: 400;
  font-size: 5vw;
}

#header-title {
  padding-right: 5%;
}

#title-div {
  margin-top: -19vw;
}

#cover-page {
  /* background-color: #213f5b; */
  background-image: url(../../static/media/grn_bg.32a25f7d.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}

#info-page,
#schedule-page {
  /* background-color: #ffffff; */
  /* background: #90cea9; */
  background: #52bfa2;
}

#info-page-dark {
  background-color: #ffffff;
}

/* #schedule-page {
  background-color: #ffffff;
} */

#top-divider {
  /* margin-top: 32px; */
  height: 16px;
  background: #7151a1;
}

#top-divider-short {
  height: 8px;
  background: linear-gradient(90deg, #d1e6a7 0%, #87bc9e 100%);
  width: 70%;
  border-radius: 16px;
  margin: auto;
}

#side-img {
  height: 50vw;
}

#side-img-2 {
  height: 50vw;
  margin: auto 40px;
}

#side-img-3 {
  height: 60vh !important;
  margin-left: 2rem;
}

/* .animate-wrapper {
  border: 1px solid red;
} */

.contact-pg-side-img {
  position: absolute;
  margin-left: -3rem;
  /* margin-top: 12rem; */
  margin-top: 4rem;
}

#main_header_img {
  background-image: url(../../static/media/landing_img.962960de.png);
  background-repeat: no-repeat;
  background-size: 100%;
  /* height: 40vw; */
  height: 50rem;
}

#header-links {
  text-decoration: none;
  text-align: center;
  padding-bottom: 32px;

  background-color: #fff;
  /* text-decoration: none;
  text-align: center;
  background-color: #fff;
  padding-bottom: 32px;
  padding: 20px; */
  /* color: #7151a1; */
}

#weekly-header-links {
  text-decoration: none;
  text-align: center;
  background: #7151a1;
  /* padding-top: 32px; */
  padding-bottom: 8px;
  /* padding-left: 32px; */
  font-size: 0.8em;
}

#weekly-link {
  text-decoration: none;
  text-align: center;
  color: #ffffff;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-weight: 600;
  font-size: 1em;
}

#weekly-link:hover {
  color: #ffffff;
}

#weekly-link-active {
  color: #ffffff;
  text-decoration: none;
  text-align: center;
  font-family: ff-tisa-web-pro, serif;
  font-weight: 600;
  font-size: 1em;
}

#hamburger {
  text-align: center;
  width: 100%;
  visibility: hidden;
}

#link-text {
  text-decoration: none;
  color: #7151a1;
  text-align: center;
  font-family: 'Abingdon Regular', ff-tisa-web-pro, serif;
  font-weight: 700;
  font-style: bold;
  font-size: 1.5em;
  padding-top: 32px;
}

#link-text:hover {
  color: #87bc9e;
}

#link-text-active {
  text-decoration: none;
  color: #ffffff;
  text-align: center;

  font-family: 'Abingdon Regular', ff-tisa-web-pro, serif;

  font-weight: 700;
  font-style: bold;
  font-size: 1.3em;
}

#white-link-text {
  text-decoration: none;
  color: #ffffff;
  text-align: center;
  font-family: ff-tisa-web-pro, serif;
  font-weight: 700;
  font-size: 1.5em;
}

#white-link-text:hover {
  color: #213f5b;
}

#link-item {
  /* margin-top: 16px; */
  margin-top: 20px;
}

#link-item-active {
  margin-top: 20px;
  background-color: #52bfa2;
}

#link-item:hover {
  background-color: #ffffff;
}

#sub-link {
  background-color: #87bc9e;
  text-decoration: none;
  color: #ffffff;
  font-family: ff-tisa-web-pro, serif;
  font-weight: 700;
  padding: 8px;
  padding-left: 32px;
  text-align: left;
}

#sub-link:hover {
  background-color: #d1e6a8;
}

#sub-link-text {
  text-decoration: none;
  text-align: center;
  color: #ffffff;
  font-family: ff-tisa-web-pro, serif;
  font-weight: 500;
}

#sub-link-text:hover {
  color: #000000;
}

#dropdown {
  height: 100%;
}

#info-header {
  color: #7151a1;
  font-size: 3em;
  font-family: 'Abingdon Bold', ff-tisa-web-pro, serif;
  /* font-weight: 400; */
  /* line-height: 1.25; */
}

/* #info-header {
  color: #213f5b;
  font-size: 2em;
  font-weight: bold;
  text-align: left;
} */

#info-header-thin {
  color: #17405e;
  font-size: 2em;
  font-family: ff-tisa-web-pro, serif;
  font-weight: 300;
  line-height: 1.25;
}

#info-content {
  color: #17405e;
  font-size: 1.25em;
  line-height: 1.15;
  font-family: ff-tisa-web-pro, serif;
  font-weight: 500;
}

#info-content a {
  color: #87bc9e;
}

#info-copy {
  max-width: 70%;
  text-align: left;
  padding-left: 10vw;
}

.purple-box {
  border: 10px solid #7151a1;
  background: #fff;
  padding: 30px;
}

#schedule-copy {
  max-width: 65%;
  text-align: left;
}

#info-header-thin {
  color: #213f5b;
  font-size: 2em;
  text-align: left;
}

#link-icon {
  max-width: 150px;
}

#icon-button {
  /* background: #7151a1 !important;
  color: #fff !important;
  margin: 16px;
  padding: 8px;
  font-size: 1em; */

  width: 70%;
  background: #7151a1 !important;
  padding: 16px;
  margin: 40px 40px 10px 40px;
  border-radius: 10px;

  font-family: ff-tisa-web-pro, serif;
  /* font-weight: 400; */
  border: none;
  font-size: 1em;
  text-decoration: none;
}

.icon-button2 {
  margin: 10px;
  width: 70%;
  background: #7151a1 !important;
  padding: 16px;
  /* margin: 40px; */
  border-radius: 10px;

  font-family: ff-tisa-web-pro, serif;
  /* font-weight: 400; */
  border: none;
  font-size: 1em;
  text-decoration: none;
}

#icon-button:hover {
  /* background-color: #b5dfc7;
  text-decoration: underline; */
  background: #fff !important;
}

#schedule-button-text:hover {
  color: #7151a1;
  text-decoration: underline;
}

.icon-button2:hover {
  background: #fff !important;
  color: #7151a1 !important;
}

#schedule-button-text {
  text-decoration: none;
  color: #fff;
  font-family: ff-tisa-web-pro, serif;
  font-weight: 700;
}

@-webkit-keyframes fade-up {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
    top: 40px;
  }

  100% {
    display: block;
    opacity: 1;
    top: 0px;
  }
}

@keyframes fade-up {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
    top: 40px;
  }

  100% {
    display: block;
    opacity: 1;
    top: 0px;
  }
}

#animate-fade-in {
  animation: fade-up 1s;
  -moz-animation: fade-up 1s; /* Firefox */
  -webkit-animation: fade-up 1s; /* Safari and Chrome */
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  position: relative;
}

@-webkit-keyframes fade-right {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
    right: -40px;
  }

  100% {
    display: block;
    opacity: 1;
    right: 0px;
  }
}

@keyframes fade-right {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
    right: -40px;
  }

  100% {
    display: block;
    opacity: 1;
    right: 0px;
  }
}

#animate-right-in {
  animation: fade-right 1s;
  -moz-animation: fade-right 1s; /* Firefox */
  -webkit-animation: fade-right 1s; /* Safari and Chrome */
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  position: relative;
}

#lt-grey-line {
  height: 2px;
  width: 100%;
  background-color: rgb(211, 211, 211);
}

#icon-grid {
  /* padding-top: 52px; */
  text-align: center;
  /* padding: 16px; */
}

#info-container {
  padding-top: 52px;
  /* align-items: center;
  margin: 0 auto;
  text-align: center; */
}

#icon-container {
  /* padding-top: 52px; */
  padding-left: 80px;
  padding-right: 80px;
}

#nottodo {
  color: #213f5b;
}

#footer-container {
  max-width: 1280px;
  margin: auto;
}

#footer-links {
  display: inline-block;
  text-decoration: none;
  text-align: center;
  width: 100%;
}

#footer-link {
  display: inline-block;
  padding: 8px;
  text-decoration: none;
  color: #3face1;
}

#footer-link a:visited {
  color: #3face1;
}

#desktop-table {
  display: table;
}

#mobile-table {
  display: none;
}

/* #copyright {
  text-align: left;
  color: #273135;
  padding-left: 5%;
  font-size: 0.5em;
  height: 100%;
} */

/* #logo {
  text-align: right;
  padding-right: 5%;
  height: 100%;
} */

#optimmize2_logo {
  text-align: center;
  margin: 0 auto;
  width: 17rem;
}

#link-padding-first {
  /* padding-left: 32px; */
}

#cover-logo {
  text-align: center;
  padding: 32px;
}

#cover-logo-img {
  max-width: 35vw;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 960px) {
  .contact-pg-side-img2 {
    text-align: center;
    margin-top: 20px !important;
  }

  .contact-pg-text {
    margin: 0 auto;
    width: 100%;
    padding-left: 0 !important;
  }

  .contact-pg-side-img {
    margin: 2rem auto;
    text-align: center;
  }

  #side-img {
    padding-top: 16px;
  }

  #desktop-table {
    display: none;
  }

  #mobile-table {
    display: table;
  }

  #side-img-3 {
    padding-top: 16px;
    margin-left: 0 !important;
  }

  .text-plus-img-wrapper {
    text-align: center;
  }

  .text-on-img {
    width: 40% !important;
    left: 13% !important;
    top: 40% !important;
  }

  .WithStyles\(ForwardRef\(AccordionSummary\)\)-content-8.WithStyles\(ForwardRef\(AccordionSummary\)\)-expanded-9 {
    border-bottom: 1px solid #fff;
  }

  #cover-logo-prim {
    text-align: center;
    padding: 8vw;
    padding-left: 0px;
    padding-right: -0px;
  }

  #cover-logo-img {
    max-width: 80vw;
  }

  #icon-container {
    /* padding-top: 52px; */
    padding-left: 10px;
    padding-right: 10px;
  }

  #hamburger {
    visibility: visible;
  }

  #link-item-padding {
    visibility: hidden;
  }

  #weekly-header-links {
    text-align: center;
  }

  #link-padding {
    margin-top: 5%;
  }

  #cover-text-container {
    margin-left: auto;
  }

  #button-grid-left {
    text-align: center;
  }

  #button-grid-right {
    text-align: center;
  }

  /* #logo {
    text-align: center;
    padding-right: 5%;
    height: 100%;
  } */

  /* #copyright {
    text-align: center;
    color: #273135;
    padding-left: 0%;
    padding-bottom: 5%;
    font-size: 0.75em;
    height: 100%;
  } */
}

/* Mobile media query */

/* @media only screen and (max-device-width: 600px) { */
@media only screen and (max-device-width: 767px) {
  .MuiTypography-h1 {
    font-size: 3rem !important;
  }

  .MuiTypography-h3 {
    font-size: 1.5rem !important;
  }

  .welcome1_text {
    top: 14% !important;
  }

  .welcome1_text h2 {
    font-size: 1.3rem !important;
    width: 50% !important;
  }

  .welcome2_text {
    font-size: 0.5rem !important;
    top: 21% !important;
  }

  .welcome3_text {
    font-size: 0.5rem !important;
    top: 24% !important;
  }

  .text-plus-img {
    margin: 3%;
    position: relative;

    margin-top: 20px !important;
  }

  .text-plus-img img {
    /* width: 30rem; */
    margin-top: 20px !important;
    width: 100% !important;
  }

  .text-on-img {
    width: 50%;
    left: 5%;
    top: 40% !important;
  }

  .WithStyles\(ForwardRef\(Accordion\)\)-root-1 {
    min-width: 100%;
  }

  #optimmize2_logo {
    width: 10rem;
    margin: -10% auto;
  }

  #main_header_img {
    height: 30rem;
  }

  #schedule-copy {
    max-width: 90%;
  }

  #weekly-header-links {
    text-align: center;
  }

  #info-header {
    max-width: 90%;
  }

  #info-header-thin {
    max-width: 90%;
  }

  #grid-button {
    width: 80%;
  }

  #info-copy {
    padding-left: 0px;
    max-width: 90%;
  }

  #side-img {
    height: 70vw;
  }

  #animate-right-in {
    -webkit-align-items: center;
            align-items: center;
    margin: 15px auto;
    text-align: center;
  }

  #side-img-2 {
    height: 60vw;
  }

  #side-img-3 {
    /* height: 50vh !important; */
    margin: 0 auto;
  }

  #cover-text {
    font-size: 10vw;
  }

  #cover-sub-text {
    font-size: 6vw;
  }

  #button-text {
    padding: 0px;
  }

  #link-item {
    padding-top: 16px;
  }
  #link-padding-first {
    padding: 0;
  }

  /* #link-text {
    font-size: 1em;
  } */
}

/* maximums */
@media only screen and (min-width: 1281px) {
  #cover-logo-img {
    max-width: 450px;
  }
  #cover-text-container {
    margin-left: -80px;
  }

  #info-copy {
    padding-left: 96px;
  }

  #side-img {
    height: 550px;
  }

  #side-img-2 {
    height: 400px;
  }

  #side-img-3 {
    max-height: 400px;
  }

  #main_header_img {
    /* height: 500px; */
  }

  #cover-text {
    font-size: 2.25em;
  }

  #cover-sub-text {
    font-size: 1.5em;
  }

  #max-width {
    max-width: 1280px;
    /* display: table; */
    margin: auto;
  }

  #header-text {
    font-size: 4em;
  }

  #title-div {
    /* margin-top: -250px; */
    margin-top: -210px;
  }

  #main-page-grid {
    height: 700px;
  }

  #privacy-legal {
    height: 700px;
  }

  #resources-header {
    height: 700px;
  }

  #trust-header {
    height: 700px;
  }

  #process-header {
    height: 700px;
  }

  #quote-image {
    width: 625px;
  }

  p {
    font-size: 1.05em;
  }

  h5 {
    font-size: 1em;
  }

  h3 {
    font-size: 1.75em;
  }

  h3.polish {
    font-size: 1.25rem;
  }

  h4 {
    font-size: 1.25em;
  }

  h2 {
    font-size: 2em;
  }

  /* #link-text {
    font-size: 1em;
  } */
}

/* Accordion style */

/* .MuiButtonBase-root {
  background: pink !important;
}

.MuiAccordionSummary-root {
  background: yellow !important;
} */

.AccordionSummary {
  /* background: #7151a1 !important;
  margin-bottom: 10px !important; */
  /* align-items: center !important; */
  color: #fff !important;
  font-size: 1.5rem;
  font-family: 'Abingdon Regular';
}

.MuiButtonBase-root.MuiAccordionSummary-root.AccordionSummary {
  background: #7151a1;
}

.MuiTypography-body1 {
  color: #fff;
}

.purple-border {
  background: #fff;
  border: 10px solid #7151a1;
  text-align: center !important;
  padding: 2em 1em;
  margin: 0 auto;
  /* style={{ margin: '0 auto', padding: '32px 0', textAlign: 'center' }} */
}

/* .MuiAccordionDetails-root {

} */

.mint-text {
  color: #52bfa2;
}

/* .black-text {
  color: '#000' !important;
} */

.WithStyles\(ForwardRef\(AccordionDetails\)\)-root-6 {
  border-top: 1px solid white;
  width: 70%;
  /* align-items: center; */
  margin: 0 auto;
}

/* Text on Image */

.text-plus-img {
  margin: 3%;
  position: relative;
}

.text-plus-img img {
  width: 31rem;
}

.text-on-img {
  position: absolute;
  top: 45%;
  left: 5%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  width: 55%;
  top: 36%;
}

.welcome_img {
  width: 100%;
}

/* Container holding the WELCOME image and the text */

.welcome_container_right {
  position: relative;
  text-align: left;
  /* color: #7151a1; */
}

.welcome1_text {
  position: absolute;
  top: 20%;
  /* top: 9%; */
  left: 8%;
}

.welcome1_text h2 {
  color: #7151a1;
  font-family: 'Abingdon Bold';
  font-size: 2.7rem;
  width: 50%;
}

.welcome_container_left {
  position: relative;
  text-align: left;
}

.welcome2_text {
  position: absolute;
  top: 26%;
  right: 6%;
  font-size: 1.1rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

  width: 60%;
}

.welcome3_text {
  position: absolute;
  top: 28%;
  left: 6%;
  font-size: 1.1rem;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */

  width: 60%;
}

#welcome3_top_fix {
  top: 20%;
}

.welcome3_text h3 {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  color: #7151a1;
}

#top-bar {
  box-shadow: 0px 5px 4px #8d8d8d;
  position: relative;
}

#top-bar-section {
  /* margin: auto; */
  border-left-style: solid;
  border-left-color: #b3b3b3;
  border-left-width: 0.1em;
  padding-left: 8px;
  text-align: center;
  -webkit-align-items: center;
          align-items: center;
  vertical-align: middle;
  /* padding-top: 30px; */
  /* line-height: 4; */
  line-height: 3;

  padding-top: 16px;
}

#top-bar-link {
  width: 100%;
  margin: 0 auto;
  /* padding-left: 14%; */
}

#top-bar-logo {
  margin-top: -15px;

  width: 55%;
  vertical-align: middle;
  /* position: relative; */
  top: 0%;
}

#flags {
  /* padding: 2px; */
  /* padding-bottom: 15px; */
  vertical-align: middle;
  margin-right: 2px;
  width: 1rem;
}

.dropdown-list {
  padding: 0;
}

.dropdown button {
  margin-top: 0 auto;
}

#qualify-icon {
  width: 15%;
  padding-right: 8px;
  vertical-align: middle;
  padding-bottom: 6px;
}

@media only screen and (max-width: 960px) {
  #top-bar-logo {
    width: 20vw;
  }

  #top-bar-section {
    padding-left: 0;
  }

  #top-bar-section p {
    text-align: center;
  }
}

#dropdownMenuButton1 {
  color: black;
  text-decoration: none;
}

/* #dropdownMenuButton1:hover {
  text-decoration: underline;
} */

.round-flag {
  border-radius: 50%;
}

.hover-color:hover {
  background-color: #7151a180;
}
