#top-bar {
  box-shadow: 0px 5px 4px #8d8d8d;
  position: relative;
}

#top-bar-section {
  /* margin: auto; */
  border-left-style: solid;
  border-left-color: #b3b3b3;
  border-left-width: 0.1em;
  padding-left: 8px;
  text-align: center;
  align-items: center;
  vertical-align: middle;
  /* padding-top: 30px; */
  /* line-height: 4; */
  line-height: 3;

  padding-top: 16px;
}

#top-bar-link {
  width: 100%;
  margin: 0 auto;
  /* padding-left: 14%; */
}

#top-bar-logo {
  margin-top: -15px;

  width: 55%;
  vertical-align: middle;
  /* position: relative; */
  top: 0%;
}

#flags {
  /* padding: 2px; */
  /* padding-bottom: 15px; */
  vertical-align: middle;
  margin-right: 2px;
  width: 1rem;
}

.dropdown-list {
  padding: 0;
}

.dropdown button {
  margin-top: 0 auto;
}

#qualify-icon {
  width: 15%;
  padding-right: 8px;
  vertical-align: middle;
  padding-bottom: 6px;
}

@media only screen and (max-width: 960px) {
  #top-bar-logo {
    width: 20vw;
  }

  #top-bar-section {
    padding-left: 0;
  }

  #top-bar-section p {
    text-align: center;
  }
}

#dropdownMenuButton1 {
  color: black;
  text-decoration: none;
}

/* #dropdownMenuButton1:hover {
  text-decoration: underline;
} */

.round-flag {
  border-radius: 50%;
}

.hover-color:hover {
  background-color: #7151a180;
}